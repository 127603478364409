import http from '@/services/http.service';
import { AxiosResponse } from 'axios';
import { ITransferPayload } from '../interfaces/transfer-payload.interface';

class TransferService {
  getStatus(company_id: string) {
    return http
      .get('/setting/transfer/status', {
        params: {
          company_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  transfer(content: ITransferPayload) {
    return http.post('/setting/transfer', content).then(({ data }: AxiosResponse) => data.data);
  }
}

export default new TransferService();
