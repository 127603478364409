
import { Component, Vue, Prop } from 'vue-property-decorator';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import { debounce } from 'lodash';

@Component({
  components: {
    DatePicker,
  },
})
export default class TransferList extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly items!: any[];

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly showFilter!: boolean;

  @Prop({
    type: String,
    default: () => [],
  })
  private readonly title!: string;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly clientFilterName!: string;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly lastUpdateUser!: string;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly loadingClients!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly itemsSelected!: string[];

  public nameToFilter: string = '';

  private setItem(itemId: string) {
    this.$emit('set-item', itemId);
  }

  public get clientsFilteredByName() {
    if (this.showFilter) {
      return this.items.filter((it: any) => it.proponent_name.toLowerCase().includes(this.nameToFilter.toLowerCase()));
    }
    return this.items;
  }

  public setFilter = debounce((name: string) => {
    if (name.length === 0) {
      this.$emit('clean-clients');
    }

    if (name.length >= 3) {
      this.$emit('input', name);
    }
  }, 800);

  private setAll(value: boolean) {
    if (value) {
      this.$emit('set-all');
      return;
    }
    this.$emit('remove-all');
  }

  private isActive(itemId: string) {
    return this.itemsSelected.some((it: string) => it === itemId);
  }

  private setLastUpdateUser(date: string) {
    this.$emit('set-last-update-user', date);
  }

  private get hasAllSelected() {
    return this.itemsSelected.length === this.items.length;
  }
}
