
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import { ITransferFilter } from './interfaces/transfer-filter.interface';
import { TransferFilter } from './entities/transfer-filter';
import TransferFilterComponent from './components/transfer-filter.component.vue';
import TransferListOrdersComponent from './components/transfer-list-orders.component.vue';
import { ClientForm } from '../form/entities/client-form.entity';
import clientService from '../../services/client.service';
import TransferListUserComponent from './components/transfer-list-user.component.vue';
import { TransferTypesEnum, ITransferPayload } from './interfaces/transfer-payload.interface';
import transferService from './services/transfer.service';
import { TransferPayload } from './entities/transfer-payload';
import TransferList from './components/transfer-list.vue';

@Component({
  components: {
    TransferFilterComponent,
    TransferListOrdersComponent,
    TransferListUserComponent,
    TransferList,
  },
  computed: { ...mapGetters(['user']) },
})
export default class Transfer extends Vue {
  private transferFilter: ITransferFilter = new TransferFilter();

  private readonly user!: any;

  private clients: any[] = [];

  private clientFilterName: string = '';

  private lastUpdateUser: string = '';

  private loadingClients: boolean = false;

  private ordersToTransfer: string[] = [];

  private usersSelected: string[] = [];

  private users: User[] = [];

  private get usersFiltered() {
    return this.users
      .filter((user: User) => user.id !== this.transferFilter.userId)
      .map(it => ({ ...it, description: it.name }));
  }

  private setLastUpdateUser(date: string) {
    this.lastUpdateUser = date;
    if (this.clientFilterName.length >= 3) {
      this.getClients();
    }
  }

  private get clientsFormatted() {
    return this.clients.map(it => ({ ...it, description: it.proponent_name }));
  }

  private setAllOrders() {
    this.ordersToTransfer = this.clients.map((it: ClientForm) => it.id);
  }

  private removeAllOrders() {
    this.ordersToTransfer = [];
  }

  private setUser(userId: string) {
    const index = this.usersSelected.findIndex((it: string) => it === userId);
    if (index >= 0) {
      this.usersSelected.splice(index, 1);
      return;
    }
    this.usersSelected.push(userId);
  }

  cleanAllClients() {
    this.clients = [];
  }

  filterByName(value: string) {
    this.clientFilterName = value;
    this.getClients();
  }

  private setAllUsers() {
    this.usersSelected = this.usersFiltered.map((it: any) => it.id);
  }

  private removeAllUsers() {
    this.usersSelected = [];
  }

  private setOrdersToTransfer(orderId: string) {
    const index = this.ordersToTransfer.findIndex((it: string) => it === orderId);
    if (index >= 0) {
      this.ordersToTransfer.splice(index, 1);
      return;
    }
    this.ordersToTransfer.push(orderId);
  }

  private async getClients() {
    this.loadingClients = true;
    const { data } = await clientService
      .getClients({
        created_by_id: this.transferFilter.userId,
        status_id: this.transferFilter.status,
        name: this.clientFilterName,
        last_date_updated: this.lastUpdateUser,
      })
      .finally(() => {
        this.loadingClients = false;
      });
    this.clients = data;
  }

  private async getAllUsers(companyId: string) {
    const users = await userService.getAllUsers({
      params: {
        company_id: companyId,
      },
    });
    this.users = users.map((it: any) => new User(it));
  }

  private async transfer(type: TransferTypesEnum) {
    const payload: ITransferPayload = new TransferPayload({
      to: this.usersSelected,
      orders: this.ordersToTransfer,
    });
    transferService
      .transfer(payload)
      .then(() => {
        this.$snackbar.open({
          text: 'Transferência realizada com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.getClients();
        this.ordersToTransfer = [];
        this.usersSelected = [];
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao transferir os clientes ',
          buttonColor: 'white',
          color: 'danger',
        });
      });
  }

  private updateFilter() {
    this.usersSelected = [];
    this.ordersToTransfer = [];
  }

  private created() {
    this.transferFilter.companyId = this.user.company_id;
    this.getAllUsers(this.transferFilter.companyId);
  }
}
