import { ITransferPayload, TransferTypesEnum } from '../interfaces/transfer-payload.interface';

export class TransferPayload implements ITransferPayload {
  public to?: string[];

  public orders?: string[];

  constructor(data: ITransferPayload) {
    this.to = data.to;
    this.orders = data.orders;
  }
}
