import { ITransferFilter } from '../interfaces/transfer-filter.interface';

export class TransferFilter implements ITransferFilter {
    public userId: string;

    public status: string;

    public companyId: string;

    constructor(data: any = {}) {
      this.userId = data.userId || '';
      this.status = data.status || '';
      this.companyId = data.companyId || '';
    }
}
