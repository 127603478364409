
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../../form/entities/client-form.entity';

@Component
export default class TransferListOrdersComponent extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly clients!: ClientForm[];

  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly ordersSelected!: string[];

  private setOrder(orderId: string) {
    this.$emit('set-order', orderId);
  }

  private setAll(value: boolean) {
    if (value) {
      this.$emit('set-all');
      return;
    }
    this.$emit('remove-all');
  }

  private isActive(orderId: string) {
    return this.ordersSelected.some((it: string) => it === orderId);
  }

  private get hasAllSelected() {
    return this.ordersSelected.length === this.clients.length;
  }
}
