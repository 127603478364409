
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import { ClientForm } from '../../form/entities/client-form.entity';

@Component
export default class TransferListUserComponent extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly users!: User[];

  @Prop({
    type: Number,
    default: () => 0,
  })
  private readonly userSelected!: Number;

  private setUser(user: number) {
    this.$emit('set-user', user);
  }
}
