
import { Component, Vue, Prop } from 'vue-property-decorator';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { TransferFilter } from '../entities/transfer-filter';
import { ITransferFilter } from '../interfaces/transfer-filter.interface';
import transferService from '../services/transfer.service';

@Component
export default class TransferFilterComponent extends Vue {
  @Prop({
    type: Object,
    default: () => new TransferFilter(),
  })
  private readonly filter!: ITransferFilter;

  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly users!: User[];

  private companies: Company[] = [];

  private status: string[] = [];

  private async setCompanyId(companyId: string) {
    this.filter.companyId = companyId;
    this.$emit('set-company');
    this.$nextTick(() => {
      this.getAllStatus();
    });
  }

  private async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private setUser(userId: string) {
    this.filter.userId = userId;
    this.$emit('change', this.filter);
  }

  private setStatus(status: string) {
    this.filter.status = status;
    this.$emit('change', this.filter);
  }

  private async getAllStatus() {
    this.status = await transferService.getStatus(this.filter.companyId);
  }

  private created() {
    this.getCompanies();
    this.getAllStatus();
  }
}
